import "./chef-cards.css";
import "../base.css";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { chefActions } from "../../store/chefSlice";
import mixpanel from "mixpanel-browser";
import { bottomBarActions } from "../../store/bottomBarSlice";
import { menuActions } from "../../store/menuSlice";
import clevertap from "clevertap-web-sdk";

const MenuButton = (props) => {
  const chefId = useSelector((state) => state.chef.chefId);
  const menuCategorieId = useSelector((state) => state.chef.menuCategorieId);
  const menu = useSelector((state) => state.bottomBar.menu);
  const from = useSelector((state) => state.bottomBar.from);
  const dispatch = useDispatch();
  const onMenuClick = () => {
    if (props.isMenuActive) {
      dispatch(menuActions.setIsMenuClicked(true));
      dispatch(bottomBarActions.loadTab({ tabName: "menu" }));
      dispatch(chefActions.onMenuClick(props.chefId));
    }
    mixpanel.track("explore-menu-clicked", {
      chefId: props.chefId,
    });
    clevertap.event.push("explore-menu-clicked", {
      "chefId": props.chefId,
    });
  };

  useEffect(() =>{
    if(props.chefId && chefId && !menu && from === "login"){
      dispatch(menuActions.setIsMenuClicked(true));
      dispatch(bottomBarActions.loadTab({ tabName: "menu" }));
      if(!menuCategorieId){
        dispatch(bottomBarActions.updateFrom({ from: "" }));
      }
    }

  },[props.chefId, chefId, menu, from])

  return (
    <div className="menu-wrapper">
      <button onClick={() => onMenuClick()} className="btn btn-menu">
        <figure>
          <img src={props.img} alt={props.alt} />
        </figure>
        <span>{props.title}</span>
      </button>
    </div>
  );
};

export default MenuButton;
