import React, { useState } from "react";
import "./menu-search.css";
import "../base.css";
import mixpanel from "mixpanel-browser";
import { useSelector } from "react-redux";
import clevertap from "clevertap-web-sdk";

const MenuSearch = ({
  setCategoriesdata,
  categories,
  menuIds,
  setMenuIdsData,
}) => {
  const dishesTab = useSelector((state) => state.bottomBar.dishes);
  const tilesClicked = useSelector((state) => state.dish.tilesClicked);

  const handleChange = (e) => {
    let value = e.target.value;
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setCategoriesdata(categories);
    else {
      console.log("MENU_SEARCH_CATEGORIES : ", categories);

      let filteredData = new Set();
      let filteredMenuIds = [];
      dishesTab && !tilesClicked
        ? categories.forEach((category) =>
            category.categories.forEach((subcategory) =>
              subcategory.dishes.forEach((dish) => {
                if (
                  dish.name?.toLowerCase().includes(lowercasedValue) ||
                  dish.description?.toLowerCase().includes(lowercasedValue) ||
                  category.name?.toLowerCase().includes(lowercasedValue)
                ) {
                  filteredData.add(category);
                }
              })
            )
          )
        : categories?.forEach((category, index) => {
            let filteredDishes = category.dishes.filter(
              (dish) =>
                dish.name.includes(lowercasedValue) ||
                (dish.description && dish.description.includes(lowercasedValue))
            );
            if (filteredDishes.length > 0) {
              let filterCategory = Object.assign({}, category);
              filterCategory.dishes = filteredDishes;
              filteredData.add(filterCategory);
              if (menuIds) filteredMenuIds.push(menuIds[index]);
            }
          });
      console.log("FILTERED_DATA :", filteredData);
      if (filteredData.length === 0) {
        setCategoriesdata([]);
      } else {
        setCategoriesdata([...filteredData]);
        if (menuIds) setMenuIdsData(filteredMenuIds);
      }
    }
    mixpanel.track("menu-searched");
    clevertap.event.push("menu-searched");
  };
  return (
    <section className="section-search-items">
      <div className="row">
        <div className="col">
          <div className="input-group">
            <button className="btn btn-search-item append-item-left">
              <img
                src="images/search_item.svg"
                alt="search-item"
                loading="lazy"
              />
            </button>
            <input
              type="text"
              className="form-control"
              placeholder="Search your favorite"
              onChange={handleChange}
            />
          </div>
        </div>
        {/* <div className="col-auto">
          <button type="button" className="btn btn-menu-bar">
            <img
              src="images/icon-menu-bar.svg"
              alt="search-item"
              loading="lazy"
            />
          </button>
        </div> */}
      </div>
    </section>
  );
};

export default MenuSearch;

{
  /* <section className={styles["section-search-items"]}>
      <div className={`${baseStyles["row"]} ${styles["row"]}`}>
        <div className={`${baseStyles["col"]} ${styles["col"]}`}>
          <div className={`${baseStyles["input-group"]} ${styles["input-group"]}`}>
            <button
              className={`${baseStyles["btn"]} ${styles["btn-search-item"]} ${baseStyles["append-item-left"]} ${styles["append-item-left"]}`}
            >
              <img
                src="images/search_item.svg"
                alt="search-item"
                loading="lazy"
              />
            </button>
            <input
              type="text"
              className={`${baseStyles["form-control"]} ${styles["form-control"]}`}
              placeholder="Search for Cuisine"
            />
          </div>
        </div>
        <div className={baseStyles["col-auto"]}>
          <button
            type="button"
            className={`${baseStyles["btn"]} ${styles["btn-menu-bar"]}`}
          >
            <img
              src="images/icon-menu-bar.svg"
              alt="search-item"
              loading="lazy"
            />
          </button>
        </div>
      </div>
    </section> */
}
