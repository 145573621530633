import React, { useEffect, Suspense, useLayoutEffect } from "react";
import BottomBar from "../BottomBar";
import Dish from "../menu/Dish";
import cartBg from "../../assets/cartBg.png";
import browseLogo from "../../assets/browseLogo.png";
import MenuTopBar from "../menu/MenuTopBar";
import AppContainer from "../util/AppContainer";
import { useSelector, useDispatch } from "react-redux";
import "./dishBook.css";
import { S3_MENU_THUMBNAIL_FOLDER } from "../../store/constants";
import { SuspenseImg } from "../util/SuspendImg";
import { bottomBarActions } from "../../store/bottomBarSlice";
import ImageLoader from "../chef/ImageLoader";
import "../menu/browse-menu-button.css";
import ReactGA from "react-ga4";
import { setBookingData } from "../../store/loginActions";
import OrderHistory from "./orderHistory";

const DishBook = () => {
  useEffect(() => {
    // ReactGA.send({ hitType: "pageview", page: "/cart" });
    ReactGA.event({
      category: "tab",
      action: "dishBook",
    });
  }, []);
  const user = useSelector(({ auth }) => auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBookingData(user.userid));
  }, [dispatch, user.userid]);

  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  };
  const isAreaServiceable = useSelector((state) => state.auth.isServiceable);

  const onBackClick = () => {
    switchTab("profile");
  };

  return (
    <AppContainer>
      <div className="cart-wrapper">
        <div className="cart-top-bar">
          <MenuTopBar
            name="Order history"
            cuisine=""
            notMenu={true}
            onBackClick={onBackClick}
          />
        </div>
        <section className="section-food-accordian">
          {user.booking && user.booking.length > 0 ? (
            <div className="food-accordian">
              <p className="cart-header">Order Details</p>
              <span style={{color:"black"}}>{navigator.userAgent}</span>
              <span style={{color:"black"}}>{navigator.userAgent.includes('Mobile/') && !navigator.userAgent.includes('Safari/')  ? `webview` : `NOT webview`}</span>
              <div>
                {[...user.booking].reverse().map((data) => (
                  <OrderHistory data={data} />
                ))}
              </div>
            </div>
          ) : (
            <>
              <div className="browseDishMain">
                <Suspense fallback={<ImageLoader className="loader_wrapper" />}>
                  <div className="browseLogo">
                    <div>
                      <SuspenseImg src={cartBg}></SuspenseImg>{" "}
                    </div>
                  </div>
                </Suspense>
                <div className="browseDetail">
                  <h5>Pre-order now, Pay later!</h5>
                  <p>
                    You can pre-order 2 dishes from the first 8 chefs now.{" "}
                    <br />
                    When we launch, you can pay and schedule your delivery.
                  </p>
                  <button onClick={() => switchTab("chef")}>
                    <img src={browseLogo} alt="browseLogo" />
                    BROWSE Chefs
                  </button>
                </div>
              </div>
            </>
          )}
        </section>
      </div>
      <BottomBar />
    </AppContainer>
  );
};

export default DishBook;
