import React, { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import BottomBar from '../BottomBar';
import "./explore.css"
import { useDispatch, useSelector } from "react-redux";
import { chefActions } from "../../store/chefSlice";

import { S3_MENU_THUMBNAIL_FOLDER } from "../../store/constants";

import SwiperCore, { EffectFade } from "swiper/core";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/effect-cube/effect-cube.min.css";
import { useEffect, useState } from "react";
import { updateExploreDishes, updatePausedDishesInfo } from '../../store/loginActions';
import ReactInstaStories from '../../components/InstaStoriesPackage/index.tsx';
import ExploreStory from './exploreStory';
import InstallModal from '../InstallModal/Index';
import VegChoiceModal from '../VegChoiceModal/Index';
import { flatten2DArray, getNextStoryImages, isWebApp } from '../../utils/helper';

SwiperCore.use([EffectFade]);


const Explore = (props) => {
  const [showInstallModal, setShowInstallModal] = useState(false)
  const [showInstallModalAnswered, setShowInstallModalAnswered] = useState(false)
  const showInstallModalAnsweredRef = useRef();
  const isAutoStoryEndRef = useRef();
  const [showChoiceModal, setShowChoiceModal] = useState(false)
  const foodChoice = localStorage.getItem("foodChoice");
  const [foodChoiceState, setFoodChoiceState] = useState(foodChoice || "");
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const viewportHeight = window.innerHeight;
  const screenHeight = isIOS ? viewportHeight : viewportHeight - 58;
  const categories = useSelector((state) => state.chef.categories);
  const user = useSelector(({ auth }) => auth.user);
  const startTimestampRef = useRef(null);

  const urlParams = new URLSearchParams(window.location.search);
  const isAppInstalled = /wv/i.test(navigator.userAgent) || !isWebApp();
  const isIosAppInstalled = navigator.userAgent.includes('Mobile/') && !navigator.userAgent.includes('Safari/') || !isWebApp();


  const dispatch = useDispatch();

  const catName = useSelector((state) => state.chef.categoryName);

  // const chefId = props.dishes.map((item) => item?.chefId);

  const [swiper, setSwiper] = useState(null);
  let storiesList = [];
  const merged = [].concat.apply([], Object.values(categories));
  const categoriesWithClass = merged?.filter((a) => a.type === "weekly")?.filter((category) => {
    if (category.img)
      return {
        ...category,
        ringClass: "ring-" + category.color,
      };
  });
  const exploreDishes = flatten2DArray(categoriesWithClass)

  let storyNum = categoriesWithClass?.findIndex(
    (category) => category.name === catName
  );

  let storySlideNumber = 0;

  const [my_swiper, set_my_swiper] = useState({});

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(storyNum)
    }
  }, [swiper])

  const swipeToNextSlide = () => {
    if (swiper) {
      const curInd = swiper.activeIndex;
      if ((curInd === categoriesWithClass.length - 1)) {
        return false;
      } else {
        return true;
      }
    }
  }

  const getStories = (isActive, dish) => {
    try {
      const descriptions = dish.stories.map((item) => item?.description);
      const headers = dish?.name;
      const price = dish?.price
      const chefId = dish?.chefId
      const description = dish?.description
      const chefImgs = dish?.chefImg;
      const chefNames = dish?.chefName
      let storyContent = [];
      const labels = dish.stories.map((d) => ({ key: d.header, desc: d.caption, img: d.img }));
      [...labels].forEach((label, i) => {
        if (label) {
          storyContent.push({
            content: ({ action, isPaused }) => {
              if (!isActive) {
                setTimeout(() => {
                  action('pause');
                }, 0);
              }
              else if (!isPaused) {
                setTimeout(() => {
                  action('play');
                }, 0);
              }
              if (showInstallModal || showChoiceModal) {
                action('pause');
              }
              return (
                <ExploreStory
                  key={dish._id}
                  dishKey={i}
                  img={label.img || dish.stories[0].img}
                  action={action}
                  labels={labels}
                  labelDesc={label}
                  dishInfo={dish}
                  isPaused={isPaused}
                  isActive={isActive}
                  headers={headers}
                  name={dish?.categoryHeader}
                  categoryImg={dish.categoryImg ? S3_MENU_THUMBNAIL_FOLDER + dish.categoryImg : ""}
                  price={price}
                  chefId={chefId}
                  description={descriptions[i] || description}
                  storyImages={dish.storyImages}
                  chefImg={chefImgs}
                  chefName={chefNames}
                  categoryId={dish.categoryId}
                />
              );
            },
            preloadResource : true
          })
        }

      });
      storiesList = storyContent;
      return storyContent;
    } catch (error) {
      console.log("Error");
    }
  };

  const handleShowModal = () => {
    showInstallModalAnsweredRef.current = true;
    setShowInstallModalAnswered(true)
    if (!!foodChoiceState && isAutoStoryEndRef.current) {
      const canSwipe = swipeToNextSlide();
      if (canSwipe) {
        storySlideNumber = 0;
        my_swiper.slideNext();
      } else {
        dispatch(chefActions.onStoryBackClick());
      }
    }
  }

  const handleFoodChoiceState = (value) => {
    const canSwipe = swipeToNextSlide();
    if (canSwipe) {
      storySlideNumber = 0;
      my_swiper.slideNext();
    } else {
      dispatch(chefActions.onStoryBackClick());
    }
    setFoodChoiceState(value)
  }

  const exploreDishesApi = (dish) => {
    const dishId = dish._id;
    const exploredDishesIdsLocal = JSON.parse(localStorage.getItem("@user") || "{}")["exploredDishesIds"] || [];
    const userexploredDishesIds = user.exploredDishesIds || [];
    const allDishesIds = [...exploredDishesIdsLocal, ...userexploredDishesIds, dishId];
    const allDishesIdsUniqueArray = [...new Set(allDishesIds)];
    const payload = { exploredDishesIds: allDishesIdsUniqueArray };
    dispatch(updateExploreDishes(user.userid, payload));
  }


  if (!categoriesWithClass || !categoriesWithClass.length) return (<div className="splash-screen">
    <img src="cuirato-main.png" alt="loading..." />
  </div>)

  const selectedFoodChoice = foodChoiceState === "VEG" ? ["veg"] : foodChoiceState === "NONVEG" ? ["non veg", "veg", "egg"] : ["non veg", "veg", "egg"];

  const allExploreDishes = exploreDishes.filter((a) => selectedFoodChoice.includes(a.type)) || [];

  const filteredExploreDishes = getNextStoryImages(allExploreDishes)

  return (
    <div>
      <Swiper
        direction={'vertical'}
        pagination={{
          clickable: true,
        }}
        height={screenHeight}
        onInit={(ev) => {
          set_my_swiper(ev)
        }}
        onSlideChange={(s) => {
          if(s.activeIndex > 0){
            const prevDish =  exploreDishes[s.activeIndex - 1];
            exploreDishesApi(prevDish)
          }
          if (!showInstallModalAnsweredRef.current && s.activeIndex === 1) {
            if (!isAppInstalled && !isIosAppInstalled) {
              setShowInstallModal(true)
            } else if (!foodChoiceState) {
              setShowChoiceModal(true)
            }
            console.log('slide change')
          }
        }}
        onSwiper={(s) => setSwiper(s)}
        className='explore-swiper-wrapper'
      >
        {filteredExploreDishes.map((dish, i) => {
          return (
            <SwiperSlide key={dish._id}>
              {({ isActive }) => (
                <ReactInstaStories
                  key={dish._id}
                  isPaused={true}
                  stories={getStories(isActive, dish)}
                  defaultInterval={5000}
                  width="100vw"
                  preloadCount={2}
                  height={screenHeight + 10}
                  progressContainerStyles={{ top: '2px' }}
                  onStoryStart={(s) => {
                    if (isActive) {
                      startTimestampRef.current = performance.now();
                      storySlideNumber = s;
                    }
                  }}
                  onStoryEnd={(s) => {
                    isAutoStoryEndRef.current = true;
                    exploreDishesApi(dish)
                    if (isActive) {
                      storySlideNumber = s;
                    }
                    if (startTimestampRef.current !== null) {
                      const endTimestamp = performance.now();
                      const timeDifference = endTimestamp - startTimestampRef.current;
                      if (timeDifference > 5300) {
                        const pausedDishesIds = user.pausedDishesIds || [];
                        const allDishesPausedIds = [...new Set([...pausedDishesIds, dish._id])]
                        const payload = { pausedDishesIds: allDishesPausedIds };
                        dispatch(updatePausedDishesInfo(user.userid, payload));
                      }
                      startTimestampRef.current = performance.now();
                    }
                    if (s === dish.stories.length - 1) {
                      if (showInstallModalAnswered || isAppInstalled || isIosAppInstalled) {
                        const canSwipe = swipeToNextSlide();
                        if (canSwipe) {
                          storySlideNumber = 0;
                          my_swiper.slideNext();
                        } else {
                          dispatch(chefActions.onStoryBackClick());
                        }
                      } else {
                        if (!isAppInstalled && !isIosAppInstalled) {
                          setShowInstallModal(true)
                        } else if (!foodChoiceState) {
                          setShowChoiceModal(true)
                        } else {
                          const canSwipe = swipeToNextSlide();
                          if (canSwipe) {
                            storySlideNumber = 0;
                            my_swiper.slideNext();
                          } else {
                            dispatch(chefActions.onStoryBackClick());
                          }
                        }
                      }
                    }
                  }}
                />
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
      <InstallModal isOpen={showInstallModal} setOpen={setShowInstallModal} setShowChoiceModal={setShowChoiceModal} foodChoiceState={foodChoiceState} setShowInstallModalAnswered={handleShowModal} />
      <VegChoiceModal isOpen={showChoiceModal} setOpen={setShowChoiceModal} setFoodChoiceState={handleFoodChoiceState} />
      <BottomBar isExplore />
    </div>
  )
}

export default Explore
