import React, { useEffect, useState } from "react";
import "./menu-categories.css";
import "../base.css";
import { useDispatch, useSelector } from "react-redux";
import { bottomBarActions } from "../../store/bottomBarSlice";
import { setStoryData } from "../../store/storieAction";
import { cartAction } from "../../store/cartSlice";
import {
  addDish,
  updatePortion,
  removeDish,
  saveToken,
} from "../../store/cartActions";
import { toast } from "react-toastify";
import { fetchDishBand } from "../../store/menuActions";
import { updateDishPrice, updateDishesCheckedInfo } from "../../store/loginActions";
import mixpanel from "mixpanel-browser";
import Joyride from "react-joyride";
import { menuActions } from "../../store/menuSlice";
import { chefActions } from "../../store/chefSlice";
import DishCheckPrice from "./DishCheckPrice";
import ReactPixel from "react-facebook-pixel";
import { getDishBandLabel } from "../../utils/timeHelper";
import { calculateCurrentPrice, combineTheDate } from "../../utils/helper";
import moment from "moment";
import clevertap from "clevertap-web-sdk";

const steps = [
  {
    title: "Swipe to browse chefs",
    content: "Stop scrolling endlessly, these chefs deserve your attention!",
    spotlightPadding: 0,
    target: ".check-price-btn",
    disableBeacon: true,
  },
];

const Dish = (props) => {
  const { orderPriceIds, isFromCart, portion, unAvailableChef = [], dish: dishInfo, quantityText } = props;
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const dish = useSelector(({ dish }) => dish);
  const chefList = useSelector((state) => state.chef.chefList);
  const currentChefDetail = chefList.find(c => c._id === props?.dish?.chefId)
  const chefTimeData = useSelector((state) => state.chef.chefTime);
  const currentChefsTime = chefTimeData[props?.dish?.chefId]

  const loggedIn = user.phonenumber ? true : false;
  const { menuCartData, cartData, scheduleTime, isScheduleNow } = useSelector(({ cart }) => cart);
  const cartDataInfo = cartData.find((c) => c._id === dishInfo._id);
  const menuCartDataInfo = menuCartData.find((c) => c.dishId === dishInfo._id);
  const scheduleDateTime = combineTheDate(scheduleTime?.date, scheduleTime?.time);

  const demoForCheckPrice = useSelector(
    (state) => state.menu.demoForCheckPrice
  );
  const setDemoForCheckPrice = useSelector(
    (state) => state.menu.setDemoForCheckPrice
  );

  const [showAdded, setShowAdded] = useState(
    cartData.map((cart) => cart._id)?.includes(props.id)
  );

  const [showAdd, setShowAdd] = useState(
    loggedIn
      ? !showAdded && user.phonenumber
        ? user.dishIdsChecked?.includes(props.id)
        : !showAdded
      : true
  ); //TO-DO: Restriction - remove dishIds check to remove checkprice
  const [showSchedule, setShowSchedule] = useState(props.isBooked);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [dishBand, setDishBand] = useState({});
  const [isAddDish, setIsAddDish] = useState(false);
  const cart = useSelector((state) => state.bottomBar.cart);
  const storie = useSelector((state) => state.bottomBar.storie);
  const dishes = useSelector((state) => state.bottomBar.dishes);
  const discountType = loggedIn
    ? user.maxDishes > user.totalDishesBooked
      ? "deepDiscount"
      : "normal"
    : "deepDiscount";
  const currentDishCount =
    cartData?.find((order) => order._id === props.id)?.portion || 0;

  const isDiscountApplied =
    (dish.dishBandLoaded && (showAdd || showAdded)) ||
    (props.isAddedOnDiscount && props.isFromOrderSummary);

  const onAddDish = async (dishId, extraPrice = 0, quantity = "") => {
    const isDishAdded = cartData.find((cart) => cart._id === dishId);
    if (isDishAdded && isDishAdded.isAddedOnDiscount) {
      toast.error("You can't add more portions for the discount price.");
      return;
    }
    if (!user.userid) {
      dispatch(bottomBarActions.loadTab({ tabName: "account" }));
    } else {
      let totalDiscountedDishesAdded = 0;
      cartData.forEach((cart) => {
        if (cart.isAddedOnDiscount == true) {
          totalDiscountedDishesAdded += 1;
        }
      });

      let isDiscountAllowed =
        user.totalDishesBooked + totalDiscountedDishesAdded < user.maxDishes;

      let isAddedOnDiscount =
        isDiscountAllowed && user.maxDishes !== 0 ? true : false;
      const payload = {
        cartPayload: {
          userId: user.userid,
          dishId: dishId,
          dealPrice: currentPrice + extraPrice,
          quantity: quantity,
          isAddedOnDiscount: isAddedOnDiscount,
          price: props.dish.price,
          portion: currentDishCount + 1,
        },
        dish: { ...props.dish, portion: currentDishCount + 1 },
        maxDishes: user.maxDishes,
        isFromCart,
        actionType: "ADD"
      };
      if (payload?.cartPayload?.portion < 1) {
        toast.error("Please add portion");
        return;
      }
      if (currentDishCount === 0) {
        dispatch(addDish(payload));
      } else {
        const cartDishInfo = cartData?.find((cart) => cart._id === dishId);
        payload.cartId = cartDishInfo.cartId;
        payload.cartPayload.dealPrice = menuCartDataInfo.dealPrice;
        dispatch(updatePortion(payload));
      }

      setShowAdd(false);
      setShowAdded(true);
      setIsAddDish(true);
      if (props.setShowAddedItemPopup) {
        props.setShowAddedItemPopup(true);
      }
    }
    mixpanel.track("added-to-cart");
    clevertap.event.push("added-to-cart");
    ReactPixel.track("AddToCart");
  };

  const onRemoveDish = async (
    dishId,
    setShowAdd,
    setShowAdded,
    cost,
    ingredientCost,
    dishBand,
    totalPortionAdded
  ) => {
    const productValue = isDiscountApplied
      ? props.dealPrice
        ? parseInt(props.dealPrice)
        : getDealPrice()
      : props.price;
    if (!props.fromCart && totalPortionAdded === 1) {
      setShowAdd(true);
      setShowAdded(false);
    }
    setIsAddDish(false);
    const payload = {
      userId: user.userid,
      dishId: dishId,
      isActive: false,
      maxDishes: user.maxDishes,
      dealPrice: productValue,
      isFromCart,
    };
    let totalDiscountedDishesAdded = 0;
    let isDiscountAllowed =
      user.totalDishesBooked + totalDiscountedDishesAdded < user.maxDishes;
    let isAddedOnDiscount =
      isDiscountAllowed && user.maxDishes !== 0 ? true : false;

    if (currentDishCount > 1) {
      const payload = {
        cartPayload: {
          userId: user.userid,
          dishId: dishId,
          dealPrice: currentPrice,
          isAddedOnDiscount: isAddedOnDiscount,
          price: props.dish.price,
          portion: currentDishCount - 1,
        },
        dish: { ...props.dish, portion: currentDishCount - 1 },
        maxDishes: user.maxDishes,
        isFromCart,
        actionType: "REMOVE"
      };
      const cartDishInfo = cartData?.find((cart) => cart._id === dishId);
      payload.cartId = cartDishInfo.cartId;
      payload.cartPayload.dealPrice = menuCartDataInfo.dealPrice;
      dispatch(updatePortion(payload));
    } else {
      payload.dealPrice = menuCartDataInfo.dealPrice;
      dispatch(removeDish(payload));
    }

    mixpanel.track("dish-removed");
    clevertap.event.push("dish-removed");
  };
  
  const onDishStorieClick = () => {
    dispatch(bottomBarActions.loadTab({ tabName: "storie" }));
    const checkedDishesInfoIds = user.checkedDishesInfoIds || []
    const payload = { checkedDishesInfoIds: [...checkedDishesInfoIds, props.id] };
    if(user?.userid){
      dispatch(updateDishesCheckedInfo(user.userid, payload));
    }
    dispatch(
      setStoryData(
        [{ img: props.mainImg, name: props.name, cuisine: props?.cuisine, description: props?.description, price: props?.price, dealPrice: isDiscountApplied && dealPrice, dish: props?.dish, discountMessage }],
        props.from
          ? props.from
          : props.fromCart
            ? "cart"
            : dishes
              ? "dishes"
              : "menu"
      )
    );
  };

  const showOneDishValidation = () => {
    toast.dismiss();
    toast.info("You can only add one portion per dish", {
      position: toast.POSITION.BOTTOM_CENTER,
      toastId: "info1",
    });
    mixpanel.track("dish-portion-increased");
    clevertap.event.push("dish-portion-increased");
  };

  const getDealPrice = () => {
    // if (props.fromCart) {
    //   const index = menuCartData.findIndex((x) => x.dishId === props.id);
    //   return index !== -1 && menuCartData[index].dealPrice
    //     ? parseInt(menuCartData[index].dealPrice)
    //     : 0;
    // }
    return parseInt(currentPrice);
  };

  const dealPrice = props.dealPrice
    ? parseInt(props.dealPrice)
    : getDealPrice()

 

  

  const updateDish = (
    dishId,
    setShowAdd,
    cost,
    ingredientCost,
    fuelCost,
    packagingCost,
    deliveryCost,
    dishBand
  ) => {
    const dishBandText = getDishBandLabel(Math.max(currentChefsTime?.chefWaitTime, currentChefDetail?.prepTime))
    const isRemovedDish = false;
    const currentPrice = calculateCurrentPrice(props.dish, currentChefsTime, currentChefDetail, false, discountType);
    dispatch(
      updateDishPrice(
        setCurrentPrice,
        false,
        dishId,
        isRemovedDish,
        props.fromCart,
        user.maxDishes,
        user.totalDishesBooked,
        orderPriceIds,
        currentPrice
      )
    );
    props.onCheckPrice(dishId, setShowAdd);
    mixpanel.track("check-price-clicked");
    clevertap.event.push("check-price-clicked");
  };

  const getTextForBand = (band, discountType) => {
    switch (discountType) {
      case "deepDiscount":
        switch (band) {
          case "HIDDEN_GEM":
            return "Hidden Gems: 70-99% off";
          case "MIDDLE":
            return "Popular Platter: 50-70% off";
          case "TRENDING":
            return "Hot Buns: 40-50% off";
          case "NORMAL":
            return "No discount";
          default:
            break;
        }
        break;
      case "normal":
        switch (band) {
          case "HIDDEN_GEM":
            return "Hidden Gems: 30-50% off";
          case "MIDDLE":
            return "Popular Platter: 20-30% off";
          case "TRENDING":
            return "Hot Buns: 10-20% off";
          case "NORMAL":
            return "No discount";
          default:
            break;
        }
        break;
    }
  };

  useEffect(() => {
    if (!props.id) return
    dispatch(fetchDishBand(props.id, setDishBand));
  }, [dispatch]);

  useEffect(() => {
    const isNotNullOrUndefined = currentChefsTime?.chefWaitTime !== null && currentChefsTime?.chefWaitTime !== undefined;
    if (dishBand.band && isNotNullOrUndefined && (showAdd || showAdded)) {
      const isScheduleOrder = !isScheduleNow && moment(scheduleDateTime).isValid();
      const currentPrice = calculateCurrentPrice(props.dish, currentChefsTime, currentChefDetail, isScheduleOrder, discountType);
      dispatch(
        updateDishPrice(
          setCurrentPrice,
          props.calculateTotal,
          props.id,
          false,
          props.isFromCart,
          user.maxDishes,
          user.totalDishesBooked,
          orderPriceIds,
          currentPrice
        )
      );
    }
  }, [dispatch, showAdd, showAdded, dishBand, currentChefsTime?.chefWaitTime, scheduleDateTime]);

  const handleJoyrideCallback = (data) => {
    const { action } = data;
    console.log("action", action);
    if (action == "close") dispatch(menuActions.setDemoForCheckPrice());
  };

  const showCheckPrice =
    !showAdd && !showAdded && !showSchedule && !props.unAvailable;

  const discountPercentage = Math.floor(
    ((props.price -
      (props.dealPrice
        ? parseInt(props.dealPrice)
        : getDealPrice())) *
      100) /
    props.price
  )

  const getDiscountMessage = () => {
    if (discountPercentage === 0) {
      return "You didn't receive a discount this time.";
    }

    if (showAdded || showAdd) {
      const discountDescription = props.isAddedOnDiscount
        ? "Deep discount! You got "
        : "Voila! You got ";

      return `${discountDescription}${discountPercentage}% off`;
    }

    return getTextForBand(dishBand.band, discountType);
  };
  const discountMessage = getDiscountMessage();
  // const discountedPrice = !!cartDataInfo && !!menuCartDataInfo?.dealPrice ? parseInt(menuCartDataInfo?.dealPrice) : dealPrice;
  const discountedPrice =  dealPrice;
  const percentageDiscount = discountedPrice * 100 / props.price || 100;
  const quantityTextLabel = quantityText || menuCartDataInfo?.quantity;

  return (
    <div className="dish-wrapper-list-item">
      {/* {!demoForCheckPrice && <Joyride steps={steps}
        callback={handleJoyrideCallback}
        disableOverlay={false}
        hideBackButton = {true}
        disableScrolling = {true}
        styles={{
          options: {
            arrowColor:"#F8EDD5",
            primaryColor: "#e8814d",
            zIndex: 10000,
            backgroundColor: '#F8EDD5',
            overlayColor: 'rgba(0, 0, 0, 0)',
          },
          tooltipTitle: {
            fontSize: 16,
            textAlign : 'left',
            paddingLeft : '10px',
            color : '#670000'
          },
          tooltipContent: {
            fontSize: 13,
            padding: '10px 10px',
            textAlign : 'left',
            fontWeight : 300,
            color : '#670000'
          },
          buttonNext : {
            fontSize : '13px'
          }
        }}
      />} */}
      <div className={props.unAvailable ? "media fade4" : "media"}>
        <div className="media-image">
          <ul className="food-list">
            {props.type?.toLowerCase() === "VEG".toLowerCase() && (
              <li className="food-list-item veg saff">
                <figure>
                  <img src="images/icon-veg.svg" alt="veg" loading="lazy" />
                  <figcaption style={{ marginTop: 2 }}>{props.type}</figcaption>
                </figure>
              </li>
            )}
            {/* {props.saff === true && <li className="food-list-item saff">
              <figure>
                <img src="images/icon-veg.svg" alt="veg" loading="lazy" />
                <figcaption>{props.type}</figcaption>
              </figure>
            </li>} */}
            {props.type?.toLowerCase() === "EGG".toLowerCase() && (
              <li className="food-list-item egg saff">
                <figure>
                  <img src="images/icon-egg.svg" alt="egg" loading="lazy" />
                  <figcaption>{props.type}</figcaption>
                </figure>
              </li>
            )}
            {props.type?.toLowerCase() === "NON VEG".toLowerCase() && (
              <li className="food-list-item non-veg saff">
                <figure>
                  <img
                    src="images/icon-non-veg.svg"
                    alt="non-veg"
                    loading="lazy"
                  />
                  <figcaption>{props.type}</figcaption>
                </figure>
              </li>
            )}
          </ul>
        </div>
        <div className="media-body">
          <div className="row m-0">
            <div className="col pl-0 left">
              <div className="media-dish-details">
                <div className="main-details">
                  <div className="dish-check-price-img-missing">
                    <h4 className="media-dish-title">{props.name}</h4>
                    {!props.img && (
                      <div className="right disk-check-price-position">
                        <DishCheckPrice
                          showAdd={showAdd}
                          setShowAdd={setShowAdd}
                          showAdded={showAdded}
                          setShowAdded={setShowAdded}
                          showSchedule={showSchedule}
                          onAddDish={onAddDish}
                          onRemoveDish={onRemoveDish}
                          updateDish={updateDish}
                          showOneDishValidation={showOneDishValidation}
                          dishBand={dishBand}
                          dish={props}
                          portion={portion}
                          cartData={cartData}
                          percentageDiscount={percentageDiscount}
                        />
                      </div>
                    )}
                  </div>
                  {props.saff === true ? (
                    <div className="media-dish-price">
                      <span className="currency">
                        {showAdd ||
                          showAdded ||
                          (props.isAddedOnDiscount && props.isFromOrderSummary)
                          ? "Current Price: "
                          : "Original Price: "}
                        &#8377;
                      </span>
                      <span
                        className={isDiscountApplied ? "price strike" : "price"}
                      >
                        {props.price}
                      </span>
                      <span className={isDiscountApplied ? "price" : "hide"}>
                        {discountedPrice}
                      </span>
                      {props.isFromOrderSummary && (
                        <span> x {portion} {portion > 1 ? "items" : "item"}</span>
                      )}
                    </div>
                  ) : (
                    <div className="media-dish-price">
                      <span className="currency">
                        {showAdd ||
                          showAdded ||
                          (props.isAddedOnDiscount && props.isFromOrderSummary)
                          ? "Current Price: "
                          : "Original Price: "}
                        &#8377;
                      </span>
                      <span
                        className={isDiscountApplied ? "price strike" : "price"}
                      >
                        {props.price}
                      </span>
                      <span className={isDiscountApplied ? "price" : "hide"}>
                        {discountedPrice}
                      </span>
                      {props.isFromOrderSummary && (
                        <span> x {portion} {portion > 1 ? "items" : "item"}</span>
                      )}
                    </div>
                  )}
                </div>
                <div className="media-dish-description">
                  <p>{props.description}</p>
                </div>
                {!!quantityTextLabel && <span className="quantity-text-label">Quantity: <span style={{ fontWeight: 500 }}>{quantityTextLabel}</span></span>}
              </div>
            </div>
            {props.img && (
              <div
                className={props.img ? "col p-0 right" : "col p-0 right flex"}
              >
                <div className="media-dish-add">
                  {props.img && (
                    <figure onClick={onDishStorieClick} className="dish-figure">
                      <span className="img-ring-red">
                        <img src={props.img} alt="profile image" />
                      </span>
                    </figure>
                  )}
                  <DishCheckPrice
                    showAdd={showAdd}
                    setShowAdd={setShowAdd}
                    showAdded={showAdded}
                    setShowAdded={setShowAdded}
                    showSchedule={showSchedule}
                    onAddDish={onAddDish}
                    onRemoveDish={onRemoveDish}
                    updateDish={updateDish}
                    showOneDishValidation={showOneDishValidation}
                    dishBand={dishBand}
                    dish={props}
                    cartData={cartData}
                    percentageDiscount={percentageDiscount}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {props.saff === true && (
        <div>
          <div className="food-panner">
            <li className="food-list-item foods">
              <figure>
                <img src={props.dish.chefImg} alt="veg" loading="lazy" />
              </figure>
            </li>
            <div className="food-dish">
              <h4 className="media-dish-title">Chef {props.dish.chefName}</h4>
              <h5 className="media-dish-title2">
                {props.category.cuisine} Cuisne Specialist
              </h5>
            </div>
          </div>
        </div>
      )}
      {unAvailableChef.includes(dishInfo.chefId) && isScheduleNow && <div className="offline-chef-status">Chef {dishInfo?.chefName} is not online</div>}
    </div>
  );
};

export default Dish;
