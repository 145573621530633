import React, { useState } from 'react';
import Sheet from 'react-modal-sheet';
import "./customizeBottomSlider.css"
import { Form } from 'react-bootstrap';


const configs = [
    {
        stiffness: 300,
        damping: 30,
        mass: 0.2
    },
    {
        stiffness: 150,
        damping: 20,
        mass: 1
    }
];

const CustomizeBottomSlider = ({ isOpen, setOpen, dishId, onAddDish, dishPortion = [], dishInfo, percentageDiscount }) => {

    const updatedDishPortion = dishPortion?.map(obj => ({
        ...obj,
        extraPrice: Math.round((obj.extraPrice * percentageDiscount / 100)) 
      }));
    const dishTags = dishInfo?.tags || []
    const [config, setConfig] = useState(configs[0]);
    const [quantity, setQuantity] = useState(updatedDishPortion.length ? updatedDishPortion[0].label : "");
    const [tags, setTags] = useState(dishTags.length ? dishTags[0] : "");
    
    return (
        <Sheet
            springConfig={config}
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            // snapPoints={[500, 0]}
            detent="content-height"
            onSnap={(snapIndex) =>
                console.log("> Current snap point index:", snapIndex)
            }
        >
            <Sheet.Container>
                <Sheet.Content>
                    
                        <div style={{ padding: "0px 15px 15px 15px", backgroundColor: "#eee", borderRadius: "7px 7px 0px 0px" }}>
                            <div className='dishInfoPortionSelector'>
                                <h6 className='dishInfoPortionSelectorHeader'>{dishInfo?.name}</h6>
                            </div>
                            {!!updatedDishPortion.length && <div className='portionSelectorBox'>
                                <div className='portionHeaderLabel'>
                                    <h6 className='dishInfoPortionSelectorQuantityHeader'>Quantity</h6>
                                </div>
                                {updatedDishPortion.map((portion) => (
                                    <div onClick={() => setQuantity(portion.label)} className='portionViewSelectorBox'>
                                        <span>{portion.label}</span>
                                        <span className='portionCheckSelectLabel'>
                                            <span>₹{portion.extraPrice}</span>
                                            <Form.Check
                                                type={"radio"}
                                                id={portion.label}
                                                checked={quantity === portion.label}
                                                className='portionCheckSelect'
                                            />
                                        </span>
                                    </div>
                                ))}
                            </div>}
                            {!!dishTags.length && <div className='portionSelectorBox'>
                                <div className='portionHeaderLabel'>
                                    <h6 className='dishInfoPortionSelectorQuantityHeader'>Tags</h6>
                                </div>
                                {dishTags.map((tag) => (
                                    <div onClick={() => setTags(tag)} className='portionViewSelectorBox'>
                                        <span>{tag}</span>
                                        <span className='portionCheckSelectLabel'>
                                            <Form.Check
                                                type={"radio"}
                                                id={tag}
                                                checked={tags === tag}
                                                className='portionCheckSelect'
                                            />
                                        </span>
                                    </div>
                                ))}
                            </div>}
                            <button
                                onClick={() => {
                                    const extraPrice = updatedDishPortion.find((a) => a.label === quantity)?.extraPrice || 0;
                                    onAddDish(dishId, extraPrice, quantity)
                                    setOpen(false)
                                }}
                                className="scheduleDeliveryConfirmBtn"
                            >
                                Add item
                            </button>
                        </div>
                    
                </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop onClick={() => setOpen(false)} />
        </Sheet>
    )
}

export default CustomizeBottomSlider
