import axios from "axios";
import { cartAction } from "./cartSlice";

export const setCartData =
  (userId, maxDishes, isCartApi = false) => async (dispatch) => {
    try {
      if (userId) {
        //dispatch(cartAction.setIsLoading({ isLoading: true }));
        const { data } = await axios(`userCart/${userId}`);
        if (data.data)
          dispatch(
            cartAction.setCart({
              cartData: data.data,
              isLoading: false,
              maxDishes,
              isCartApi
            })
          );
      }
    } catch ({ ...error }) {
      console.log(error);
    }
  };

export const setMenuCartData = (userId) => async (dispatch) => {
  try {
    if (userId) {
      //dispatch(cartAction.setIsLoading({ isLoading: true }));
      const { data } = await axios(`cart/${userId}`);
      if (data.data)
        dispatch(
          cartAction.setMenuCart({ cartData: data.data, isLoading: false })
        );
    }
  } catch ({ ...error }) {
    console.log(error);
  }
};

export const addDish = (body) => async (dispatch) => {
  try {
    // TO-DO: add to show loading page
    const { data } = await axios.post("cart", body.cartPayload);
    if (data.data)
      dispatch(
        cartAction.addCartData({
          cartData: {...body.dish, cartId : data.data._id},
          isLoading: false,
          cartPayload: body.cartPayload,
          maxDishes: body.maxDishes,
          isFromCart: body.isFromCart,
          actionType: body.actionType,
        })
      );
    // TO-DO: Add when Cart is shown on menu page, add cart api should return dish.
  } catch ({ ...error }) {
    console.log(error);
  }
};

export const updatePortion = (body) => async (dispatch) => {
  try {
    // TO-DO: add to show loading page
    const { data } = await axios.post(`cart/update/${body.cartId}`, {portion : body.cartPayload.portion});

    if (data.data)
      dispatch(
        cartAction.addCartData({
          cartData: {...body.dish, cartId : body.cartId},
          isLoading: false,
          cartPayload: body.cartPayload,
          maxDishes: body.maxDishes,
          isFromCart: body.isFromCart,
          actionType: body.actionType,
        })
      );
    // TO-DO: Add when Cart is shown on menu page, add cart api should return dish.
  } catch ({ ...error }) {
    console.log(error);
  }
};


export const saveToken = (body) => async (dispatch) => {
  try {
    const { data } = await axios.post("token", body);
    if (data.data){
      console.log("Token saved")
    }
  } catch ({ ...error }) {
    console.log(error);
  }
};

export const removeDish = (body) => async (dispatch) => {
  try {
    //dispatch(cartAction.setIsLoading({ isLoading: true }));
    const { data } = await axios.delete(
      "cart/" + body.userId + "/" + body.dishId
    );
    if (data.data)
      dispatch(
        cartAction.removeCardData({
          dishId: body.dishId,
          isLoading: false,
          maxDishes: body.maxDishes,
          dealPrice: body.dealPrice,
          isFromCart: body.isFromCart,
        })
      );
  } catch ({ ...error }) {
    console.log(error);
  }
};
