import "./App.css";
import React, { useEffect, useState } from "react";
import AppWrapper from "./components/util/AppWrapper";
import Aside from "./components/util/Aside";
import Home from "./components/Home";
import Menu from "./components/menu/Menu";
import Dishes from "./components/search/Dishes";
import { useDispatch, useSelector } from "react-redux";
import DishStories from "./components/stories/DishStories";
import withSplashScreen from "./components/withSplashScreen";
import Cart from "./components/cart/Cart";
import UserProfile from "./components/userprofile/Index";
import Login from "./components/login/Login";
import SingleStorePage from "./components/SingleStorePage/Index";
import Underconstruction from "./components/underconstruction/underconstruction";
import Checkout from "./components/cart/Checkout";
import OrderSucessfull from "./components/cart/OrderSucessfull";
import DishBook from "./components/dishBook/DishBook";
import ReactGA from "react-ga4";
import Refer from "./components/refer/Refer";
import { reloadUser } from "./store/loginActions";
import mixpanel from "mixpanel-browser";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  FB_PIXEL_CODE,
  GOOGLE_ANALYTICS_KEY_DEV,
  GOOGLE_ANALYTICS_KEY_PROD,
  MIXPANEL_AUTH_KEY_DEV,
  MIXPANEL_AUTH_KEY_PROD,
} from "./store/constants";
import FAQ from "./components/userprofile/FAQ";
import About from "./components/userprofile/About";
import { setCartData, setMenuCartData } from "./store/cartActions";
import runOneSignal from "./OneSignal";
import Location from "./components/location/Location";
import { bottomBarActions } from "./store/bottomBarSlice";
import LocationSelector from "./components/location/LocationSelector";
import Address from "./components/address/Address";
import AddressForm from "./components/address/AddressForm";
import Tracking from "./components/OrderTracker/Tracking";
import OrderSummary from "./components/orderSummary/OrderSummary";
import BannerInfo from "./components/banner/BannerInfo";
import ReactPixel from "react-facebook-pixel";
import LegalView from "./components/LegalView/Index";
import PrivacyPage from "./components/PrivacyPage/Index";
import ChefOrderReviewWrite from "./components/ChefOrderReviewWrite/Index";
import Explore from "./components/explore/Index";
import clevertap from "clevertap-web-sdk";


function App() {
  const dispatch = useDispatch();
  let menuClicked = useSelector((state) => state.chef.menuClicked);
  let menu = useSelector((state) => state.bottomBar.menu);
  let chefId = useSelector((state) => state.chef.chefId);
  let storiesActivated = useSelector((state) => state.chef.storiesActivated);
  let storyImg = useSelector((state) => state.chef.storyImg);
  let categoryName = useSelector((state) => state.chef.categoryName);
  let categoryDishes = useSelector((state) => state.chef.dishes);
  const displayExplore = useSelector((state) => state.bottomBar.explore);
  let categories = useSelector((state) => state.chef.categories);
  let displayDishes = useSelector((state) => state.bottomBar.dishes);
  let displayCart = useSelector((state) => state.bottomBar.cart);
  let profile = useSelector((state) => state.bottomBar.profile);
  let faq = useSelector((state) => state.bottomBar.faq);
  let about = useSelector((state) => state.bottomBar.about);
  let bannerInfo = useSelector((state) => state.bottomBar.bannerInfo);
  let displayAccount = useSelector((state) => state.bottomBar.account);
  let displayBenefits = useSelector((state) => state.bottomBar.benefits);
  let storie = useSelector((state) => state.bottomBar.storie);
  let menuactive = useSelector((state) => state.chef.menuactive);
  let cart = useSelector((state) => state.bottomBar.cart);
  let checkout = useSelector((state) => state.bottomBar.checkout);
  let from = useSelector((state) => state.bottomBar.from);
  let isOrderSucessfull = useSelector(
    (state) => state.bottomBar.isOrderSucessfull
  );
  let chefCategories = categories[chefId];
  let displayDishBook = useSelector((state) => state.bottomBar.dishBook);
  const displayLegalView = useSelector((state) => state.bottomBar.legalView);
  const orderReviewWriteView = useSelector((state) => state.bottomBar.orderReviewWrite);
  let underconstruction = useSelector(
    (state) => state.underconstruction.underconstruction
  );
  let refer = useSelector((state) => state.refer.isShownRefer);
  let dishclicks = useSelector((state) => state.bottomBar.dishclick);
  let isEditUserDetail = useSelector(
    (state) => state.bottomBar.isEditUserDetail
  );
  let locationSelector = useSelector(
    (state) => state.bottomBar.locationSelector
  );

  let location = useSelector((state) => state.bottomBar.location);
  let address = useSelector((state) => state.bottomBar.address);
  let addressForm = useSelector((state) => state.bottomBar.addressForm);
  let tracking = useSelector((state) => state.bottomBar.tracking);
  let orderSummary = useSelector((state) => state.bottomBar.orderSummary);
  const user = useSelector(({ auth }) => auth.user);
  let displayChef = useSelector((state) => state.bottomBar.chef);
  let mpUserInitiated = localStorage.getItem("mpUserId");
  const [showInstallModal, setShowInstallModal] = useState(true)

  useEffect(() => {
    runOneSignal();
    if (user && user.userid) dispatch(reloadUser(user.userid));
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const payment = urlParams.get('payment')
    if (!!payment) {
      dispatch(bottomBarActions.loadTab({ tabName: "checkout" }));
    }
  }, []);

  useEffect(() => {
    if (user.userid) {
      dispatch(setMenuCartData(user.userid));
      clevertap.profile.push({
        "Site": {
          "Name": user.username,
          "Identity": user.userid,
          "Email": user.email,
          "Phone": user.phonenumber,
        }
       })
    }
  }, [user.userid]);

  useEffect(() => {
    if (user.userid) {
      dispatch(setCartData(user.userid, user.maxDishes));
    }
  }, [user.userid]);

  const isMobileDevice = () => {
    /* Storing user's device details in a variable*/
    let details = navigator.userAgent;

    /* Creating a regular expression
    containing some mobile devices keywords
    to search it in details string*/
    let regexp = /android|iphone|kindle|ipad/i;

    /* Using test() method to search regexp in details
    it returns boolean value*/
    let isMobileDevice = regexp.test(details);
    return isMobileDevice;
  };

  ReactGA.initialize(GOOGLE_ANALYTICS_KEY_PROD);
  mixpanel.init(MIXPANEL_AUTH_KEY_PROD, { debug: true });
  if (!mpUserInitiated) {
    let mpUserId = Math.floor(100000 + Math.random() * 900000);
    mixpanel.identify(mpUserId);
    localStorage.setItem("mpUserId", mpUserId);
  }
  const advancedMatching = { em: "ashwin@cuirato.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  ReactPixel.init(FB_PIXEL_CODE, advancedMatching, options);
  ReactPixel.pageView(); // For tracking page view

  return (
    <AppWrapper>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                {underconstruction && <Underconstruction />}
                {refer && <Refer />}
                {isOrderSucessfull && <OrderSucessfull />}
                {checkout && <Checkout />}
                {storiesActivated && (
                  // <TryingStories/>
                  <DishStories
                    categories={chefCategories}
                    dishes={categoryDishes}
                    // eslint-disable-next-line no-undef
                    id={
                      displayAccount && !displayChef
                        ? "menuclick"
                        : "menuunclick"
                    }
                    headerImg={storyImg}
                    name={categoryName}
                  />
                )}
                {displayExplore && <Explore />}
                {/* {dishstore &&<DishStories />} */}
                {storie && <SingleStorePage />}
                {displayAccount && <Login />}
                {displayBenefits && <Login showBenefits={true} />}
                {isEditUserDetail && <Login activeStepper={4} />}
                {dishclicks && (
                  <Dishes id={!displayDishes ? "dishclick" : "dishunclick"} />
                )}
                {displayCart && <Cart />}
                {faq && <FAQ />}
                {about && <About />}
                {bannerInfo && <BannerInfo />}
                {profile && <UserProfile />}
                {displayDishBook && <DishBook />}
                {displayLegalView && <LegalView />}
                {orderReviewWriteView && <ChefOrderReviewWrite />}
                {locationSelector && <LocationSelector from={from} />}
                {location && <Location />}
                {address && <Address />}
                {addressForm && <AddressForm />}
                {tracking && <Tracking />}
                {orderSummary && <OrderSummary />}
                {menu && (
                  <Menu
                    // eslint-disable-next-line no-undef
                    id={storie || !displayChef ? "menuclick" : "menuunclick"}
                    chefId={chefId}
                  />
                )}
                {isMobileDevice() ? (
                  <Home
                    chefId={chefId}
                    id={
                      orderSummary ||
                        tracking ||
                        address ||
                        addressForm ||
                        locationSelector ||
                        storie ||
                        profile ||
                        faq ||
                        about ||
                        bannerInfo ||
                        menuactive ||
                        menu ||
                        displayAccount ||
                        isEditUserDetail ||
                        storiesActivated ||
                        displayDishes ||
                        underconstruction ||
                        refer ||
                        cart ||
                        displayExplore ||
                        checkout ||
                        isOrderSucessfull ||
                        displayDishBook ||
                        displayLegalView ||
                        orderReviewWriteView
                        ? "dataclick"
                        : "dataunclick"
                    }
                  />
                ) : (
                  <Login initial={true} />
                )}
                {/* {<Home chefId={chefId} id={storie ||  profile ||menuactive ||  menuClicked || displayAccount ||  storiesActivated ||  displayDishes || account || cart ? "dataclick" : "dataunclick"} />} */}
                <Aside img="images/chef-preparing-pasta-desktop-banner.jpg" />
                {/* <InstallModal isOpen={showInstallModal} setOpen={setShowInstallModal} /> */}
              </>
            }
          />
          <Route path="/privacy" element={<PrivacyPage />} />
        </Routes>
      </BrowserRouter>
    </AppWrapper>
  );
}

export default withSplashScreen(App);
