import { useDispatch, useSelector } from "react-redux";
import { bottomBarActions } from "../../store/bottomBarSlice";
import "./orderStatus-badge.css";
import { useEffect, useState } from "react";
import { orderActions } from "../../store/orderSlice";
import { API_TEST, ORDER_EXPIRY_TIME } from "../../store/constants";
import { loginActions } from "../../store/loginSlice";
import { fetchDriverLocation } from "../../store/orderActions";
import moment from "moment";

const OrderStatusBadge = (props) => {
  const { visible } = props
  const dispatch = useDispatch();
  const [intervalRef, setIntervalRef] = useState(null);
  const activeOrderDetails = useSelector((state) => state.auth.orderDetails);
  const orderDeliveredDate = useSelector(
    (state) => state.order.deliveredDate
  );
  const singleOrderHistoryDetails = useSelector((state) => state.auth.singleOrderHistoryDetails);
  const orderDetails = !!singleOrderHistoryDetails ? singleOrderHistoryDetails : activeOrderDetails
  const orderStatusReducer = useSelector((state) => state.order.orderStatus);
  const estimatedTimeReducer = useSelector((state) => state.order.estimatedTime);
  const driverLocationReducer = useSelector((state) => state.order.driverLocation);
  const switchTab = (tabName) => {
    props.orderType == "order-now" ? 
    dispatch(bottomBarActions.loadTab({ tabName: "orderSummary" }))
    :dispatch(bottomBarActions.loadTab({ tabName: "dishBook" }));
  };

  const getOrderStatus = () => {
    switch (orderStatusReducer) {
      case "CREATED":
        return "Your Order is Placed";
      case "CONFIRMED":
        return "Order Confirmed";
      case "READY":
        return "Order is Ready";
      case "PICKED_UP":
        return "Your Order is on the way";
      case "DELIVERED":
        return "Your Order is Delivered";
      default:
        return "Your Order is in progress";
    }
  };

  const isOrderDeliveredMoreThan2HoursAgoFun = (orderDeliveredDate) => {
    const currentMoment = moment();
    const orderDeliveredMoment = moment(orderDeliveredDate);
    const differenceInMinutes = currentMoment.diff(orderDeliveredMoment, "minutes");
  
    return differenceInMinutes > 120; // 2 hours = 120 minutes
  };

  const isBadgeVisible = () => {
    if (orderDetails?.createdAt && orderDetails?.orderType == "order-now") {
      let { createdAt, updatedAt } = orderDetails;
      let orderedDate = new Date(updatedAt ? updatedAt : createdAt);
      orderedDate.setTime(
        orderedDate.getTime() + ORDER_EXPIRY_TIME * 60 * 60 * 1000
      );
      orderedDate = orderedDate.toLocaleString();
      let curDate = new Date();
      curDate = curDate.toLocaleString();

      if (orderedDate < curDate) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const orderId = orderDetails._id;
      dispatch(fetchDriverLocation(orderId));
      if (estimatedTimeReducer !== 0) {
        dispatch(orderActions.changeEstimatedTime({ time: estimatedTimeReducer - 1 }));
      } else if (estimatedTimeReducer === 0) {
        dispatch(orderActions.changeEstimatedTime({ time: orderDetails.deliveryTime || 0 }));
      }
      try {
        const response = await fetch(API_TEST + `getOrderStatus/${orderId}`);
        const jsonData = await response.json();
        const status = jsonData.data.status;
        const driverName = jsonData.data?.driverName || "";
        const driverPhoneNumber = jsonData.data?.driverPhoneNumber || "";
        const deliveredDate = jsonData.data?.deliveredDate || "";
        dispatch(orderActions.changeOrderStatus({ status: status, driverName, driverPhoneNumber, deliveredDate }));
        // TO:DO - Stop interval without refresh
        // if(orderDetails.status != status){
        //   let newOrderDetails = { ...orderDetails };
        //   newOrderDetails.status = status;
        //   dispatch(loginActions.setOrderDetails(newOrderDetails));
        // }
        // if(status == "DELIVERED"){
        //   clearInterval(stateInterval);
        // }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Clear the previous interval when orderDetails._id changes
    if (intervalRef) {
      clearInterval(intervalRef);
    }

    if (!!singleOrderHistoryDetails ? true : (orderDetails._id && isBadgeVisible())) {
      fetchData();
      // Start a new interval and store the reference
      const interval = setInterval(fetchData, 20000);
      setIntervalRef(interval);
      return () => clearInterval(interval);
    }
  }, [orderDetails._id]);

  const isOrderDeliveredMoreThan2HoursAgo = moment(orderDeliveredDate).isValid() && isOrderDeliveredMoreThan2HoursAgoFun(orderDeliveredDate);
  
  if(!visible || isOrderDeliveredMoreThan2HoursAgo) return<></>

  return (
    <div
      className="order_status-badge"
      onClick={() => switchTab("orderSummary")}
    >
      {props.orderType == "order-now" ? (
        <><p>{getOrderStatus()}</p>
        <span>View</span></>
      ) : (
        <><p>You have a pre-order</p>
        <span>Schedule</span></>
      )}
      
    </div>
  );
};

export default OrderStatusBadge;
