import { useState, useEffect } from "react";
import CustomizeBottomSlider from "../customizeBottomSlider/Index";



const DishCheckPrice = ({
    showAdd, setShowAdd, showAdded, portion, setShowAdded, showSchedule, onAddDish, onRemoveDish, updateDish, percentageDiscount, dishBand, dish, cartData
}) => {
    const { isFromCart } = dish
    const [isOpen, setOpen] = useState(false);

    const handleAddToCartClick = () => {
        onAddDish(dish.id)
        // if (dish?.dish?.portion?.length || dish?.dish?.tags?.length) {
        //     setOpen(true)
        // } else {
        //     onAddDish(dish.id)
        // }
    }

    const dishPortion = dish?.dish?.portion || [];
    const dishInfo = dish?.dish;

    const totalCartAddedDishCount = cartData?.find((order) => order._id === dish.id)?.portion || 0;

    useEffect(() => {
        if (!showAdd &&
            !showAdded &&
            !showSchedule &&
            !dish.unAvailable) {
            updateDish(
                dish.id,
                setShowAdd,
                dish.price,
                dish.dish.ingredientCost,
                dish.dish.fuelCost,
                dish.dish.packagingCost,
                dish.dish.deliveryCost,
                dishBand.band
            )
        }

    }, [showAdd, showAdded, showSchedule, dish.unAvailable])
    return (
        <div className="dish-add-wrapper">
            {!showAdd &&
                !showAdded &&
                !showSchedule &&
                !dish.unAvailable ? (
                <button
                    type="button"
                    className={
                        dish.img
                            ? "btn btn-add check-price-btn"
                            : "btn btn-add check-price-btn bottom1"
                    }
                    onClick={() =>
                        updateDish(
                            dish.id,
                            setShowAdd,
                            dish.price,
                            dish.dish.ingredientCost,
                            dish.dish.fuelCost,
                            dish.dish.packagingCost,
                            dish.dish.deliveryCost,
                            dishBand.band
                        )
                    }
                >
                    <span>Check Price</span>
                    <img
                        src="images/Icons/Icon-06.svg"
                        alt="search-item"
                        loading="lazy"
                    />
                </button>
            ) : (
                ""
            )}
            {showAdd && !showSchedule && !dish.unAvailable ? (
                <button
                    type="button"
                    className={
                        dish.img ? "btn btn-add" : "btn btn-add bottom1"
                    }
                    onClick={handleAddToCartClick}
                >
                    <span>Add to Cart</span>
                    <img
                        src="images/icon-plus.svg"
                        alt="search-item"
                        loading="lazy"
                    />
                </button>
            ) : (
                ""
            )}

            {dish.unAvailable && !showSchedule && (
                <button type="button" className="btn btn-add">
                    <span>unavailable</span>
                    <img
                        src="images/access-denied-icon.svg"
                        alt="search-item"
                        loading="lazy"
                    />
                </button>
            )}

            {showAdded && !showSchedule && !dish.unAvailable ? (
                <div
                    className={
                        dish.img
                            ? "input-group add-items"
                            : "input-group add-items bottom1 ml-2"
                    }
                >
                    <button
                        className="btn btn-minus-item"
                        onClick={() =>
                            onRemoveDish(
                                dish.id,
                                setShowAdd,
                                setShowAdded,
                                dish.price,
                                dish.dish.ingredientCost,
                                dishBand.band,
                                totalCartAddedDishCount
                            )
                        }
                    >
                        <img
                            src="images/icon-minus.svg"
                            alt="search-item"
                            loading="lazy"
                        />
                    </button>
                    <div>{totalCartAddedDishCount}</div>
                    <button
                        className="btn btn-add-item"
                        onClick={() => onAddDish(dish.id)}
                    >
                        <img
                            src="images/icon-plus.svg"
                            alt="search-item"
                            loading="lazy"
                        />
                    </button>
                </div>
            ) : (
                ""
            )}
            {showSchedule && dish.isScheduleActive ? (
                <div className="input-group add-items item-schedule">
                    <button
                        type="button"
                        className="btn btn-added btn-schedule"
                    >
                        <span> Schedule </span>
                    </button>
                    <img src="images/time-period.svg" />
                </div>
            ) : (
                ""
            )}
            {!isFromCart && <CustomizeBottomSlider isOpen={isOpen} dishId={dish.id} onAddDish={onAddDish} percentageDiscount={percentageDiscount} setOpen={setOpen} dishPortion={dishPortion} dishInfo={dishInfo} />}
        </div>
    );
}

export default DishCheckPrice;
